/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Kanit';
  src: url('../assets/fonts/Kanit-Regular.ttf') format('woff');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Kanit-Bold';
  src: url('../assets/fonts/Kanit-Bold.ttf') format('woff');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Kanit-SemiBold';
  src: url('../assets/fonts/Kanit-SemiBold.ttf') format('woff');
  font-style: normal;
  font-display: swap;
}


*,
html,
body {
  font-family: 'Kanit';
}

@media only screen and (max-width: 600px) {
  .h-mobile {
    height: calc(100vh - 215px);
  }
  .h-menu {
    height: calc(100vh - 128px);
  }
}
@media only screen and (min-width: 600px) {
  .h-pc {
    height: calc(100vh - 140px);
  }
}